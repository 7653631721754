.viewport[disabled] {
  pointer-events: none;

  & .hotspot-door,
  & .hotspot-door-ground {
    pointer-events: none;
  }
}

.backdrop {
  transition: opacity 0.2s ease;
  position: absolute;
  inset: 0;
  background: #000;
  opacity: 0.7;
  z-index: 9;
}

.lobby-modal {
  max-height: 95vh;
  overflow: auto;
  opacity: 1;
  transition: opacity 0.2s ease, transform 0.2s ease;
  position: absolute;

  background: white;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  gap: 1em;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 0px;
  gap: 2em;
  padding: 1em 2em;

  @media screen and (min-width: 990px) {
    flex-direction: row;
    padding: 4em;
    gap: 2em;
  }
}

.presentation-lobby {
  &[data-disabled] > .backdrop {
    opacity: 0;
  }
  &[data-disabled] > .lobby-modal {
    opacity: 0;
    transform: scale(0);
  }

  .select-devices {
    max-width: 400px;
    position: relative;

    .room-actions {
      position: absolute;
      bottom: 30%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 2em;
      z-index: 9;

      @media screen and (min-width: 990px) {
        bottom: 25%;
      }
    }
  }

  .join-presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn-join-presentation {
    background: #1bad8f;
    border-radius: 4px;
    padding: 0.5em 1em;
    text-align: center;
    border: 0;
    font-size: 1.25rem;
    text-align: center;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #49bda5;
    }
    &:focus {
      outline-color: #34334c;
    }
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed !important;
    }
  }

  .device-inputs {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    @media screen and (min-width: 990px) {
      margin-top: 2em;
    }
  }

  .video-container {
    display: grid;
    place-items: center;
    video {
      max-width: 200px;
      border-radius: 10px;
      object-fit: cover;
      aspect-ratio: 1 / 1;
      position: relative;

      @media screen and (min-width: 990px) {
        max-width: 400px;
      }
    }

    .video-placeholder {
      max-width: 200px;
      height: 200px;
      width: 100%;
      aspect-ratio: 1 / 1;
      display: grid;
      place-items: center;
      background: #333;
      color: #fff;
      font-size: 1rem;
      text-align: center;
      @media screen and (min-width: 990px) {
        max-width: 400px;
        height: 400px;
        aspect-ratio: 1 / 1;
        font-size: 1.5rem;
      }
    }
  }

  .presentation-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75em;
    text-align: center;
    margin-bottom: 1em;
    flex-direction: column;

    @media screen and (min-width: 990px) {
      margin-bottom: 3em;
    }

    strong {
      font-size: 1.2rem;

      @media screen and (min-width: 990px) {
        font-size: 2em;
      }
    }
  }

  .user-data-form {
    margin-bottom: 1em;
    width: 100%;

    .textinput {
      width: 100%;
    }
  }
}

.presentation-participants {
  *[data-disabled] {
    display: none;
    pointer-events: none;
  }

  .video-container {
    position: absolute;
    top: 1em;
    left: 1em;
    display: flex;

    & > div {
      position: relative;
    }

    video,
    .webcam-placeholder {
      height: 8rem;
      width: 8rem;
      margin-right: 1rem;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      background-color: #1bad8f;
      color: #fff;
      font-size: 4rem;
      text-transform: uppercase;
      font-weight: 700;
      display: grid;
      place-items: center;
      border-radius: 10px;
    }
  }

  .room-actions {
    z-index: 9;
    position: absolute;
    right: 90px;
    bottom: 10px;
    display: flex;
    gap: 20px;
  }

  .indicator-container {
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    display: flex;
    gap: 1rem;
    .disabled-indicator {
      background-color: rgb(247, 104, 91);
      color: #fff;
      width: 32px;
      height: 32px;
      display: grid;
      place-items: center;
      border-radius: 9999px;

      svg {
        width: 100%;
        height: 100%;
        padding: 0.25em;
      }
    }
  }
}

.text-white {
  color: #fff;
}

.control-indicator {
  position: absolute;
  bottom: 90px;
  right: 15px;
  display: flex;
  gap: 1rem;
  align-items: center;

  .dot-container {
    display: flex;
    height: 1rem;
    width: 1rem;
    position: relative;
  }

  .ping {
    position: absolute;
    display: inline-flex;
    height: 100%;
    width: 100%;
    border-radius: 9999px;
    background-color: rgb(248, 113, 113);
    opacity: 0.75;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .static {
    position: relative;
    display: inline-flex;
    border-radius: 9999px;
    height: 1rem;
    width: 1rem;
    background-color: rgb(239, 68, 68);
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.action-button {
  all: unset;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--action-btn-bg, rgb(228, 230, 237));
  color: var(--action-btn-text, #000);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 10px rgba(241, 242, 250, 0.6);
  cursor: pointer;
  display: grid;
  place-items: center;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  svg {
    padding: 1rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.leave-button {
    --action-btn-bg: rgb(247, 104, 91);
    --action-btn-bg-hover: rgb(214, 90, 78);
    --action-btn-text: #fff;
  }

  &:hover,
  &.active {
    background-color: var(--action-btn-bg-hover, rgb(211, 216, 243));
  }
}
