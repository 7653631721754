$semi-gray: rgba(128, 128, 128, 0.6);
$light-pure: #ffffff;
$light: #e4e4e4;
$sb-green: hsl(165, 55%, 48%);
$sb-red: hsl(357, 55%, 48%);
$light-weak: rgba(200, 200, 200, 1);
$semi-light: rgba(255, 255, 255, 0.8);
$dark: rgb(128, 128, 128);
$semi-dark: rgba(0, 0, 0, 0.4);
$semi-blue: hsl(197, 89%, 93%);
$semi-green: hsl(164, 65%, 56%);
$shadow: rgba(0, 0, 0, 0.2);
$shadow-weaker: rgba(0, 0, 0, 0.14);
$shadow-weakest: rgba(0, 0, 0, 0.12);
$semi-dark-blue: rgba(178, 205, 216, 0.84);
$semi-strong-gray: rgba(128, 128, 128, 0.8);
$overlay-dark: rgba(0, 0, 0, 0.5);
$primary: hsl(211, 28%, 24.5%);
$primary-500: #1bac8f;
$background-dark: #01001fb3;
$background-active: #44436eb3;

.small {
  font-size: 0.75rem;
}

.text-muted {
  color: #989898;
  font-weight: 100;
  font-style: italic;
}

#lead-page-maximize {
  cursor: pointer;
  position: absolute;
  bottom: -5px;
  right: 48px;
  width: 50px;
  height: 50px;
  z-index: 9999;

  .leadPage-viewport-icon {
    color: rgba(255, 255, 255, 0.78);
    font-size: 25px;
  }
}

.button-x {
  position: absolute;
  top: 6px;
  right: 13px !important;
  cursor: pointer;
}

.card {
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0 4px 16px #272727;

  &.show {
    display: block;
    transition: height 0.5s ease-in;
  }

  .card-header {
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;

    .card-close {
      cursor: pointer;
      position: absolute;
      top: -23px;
      right: -10px;
      height: 30px;
      width: 30px;
      line-height: 27px;
      font-size: 14px;
      border-radius: 50%;
      padding: 0;
      text-align: center;
    }

    .card-header-title {
      padding: 0.75rem 1.5rem;
      margin: 0;
      font-size: 1.25rem;
      margin-top: 10px;
      color: #313131;
      font-weight: 300;
    }
  }

  .card-body {
    overflow: hidden;
    padding: 1rem;
    padding-top: 0;
  }

  .card-block + .card-block {
    margin-top: 2rem;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: $light;
  font-family: "Inter", sans-serif;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

.blur {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
}

.viewport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .hotspot {
    display: none;
    position: relative;
    width: 100px;
    height: 100px;
    padding: 50px; /*= 2em * 1.4 (2em = half the width of an img, 1.4 = sqrt(2))*/
    border-radius: 50%;
    margin-left: -51px;
    margin-top: -51px;
    color: $light;
    transform: scale(1);
    /*opacity: 1;
		transition: all 0.25s;

		.switching {
			opacity: 0 !important;
			transform: scale(0.5) !important;
		}*/
    &:hover {
      color: $light-pure;
    }

    &.visible {
      display: block;
    }

    .label {
      font-size: 12px;
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      width: min-content;
      margin: 0 auto;
      text-align: center;
      text-shadow: 0 0 2px black, 0 0 2px black;
      font-weight: 600;
      text-transform: uppercase;
      transform: translateX(-50%);
    }

    .outer-circle {
      width: 85px;
      height: 85px;
      background-color: rgba(255, 255, 255, 0.4);
      transform: translate(-50%, -50%);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        .info-cloud:not(.no-content) {
          clip-path: inset(0 0 0 0 round 5px);
        }
      }
    }

    .drop-shadow {
      width: 60px;
      height: 60px;
      background-color: $primary-500;
      border-radius: 50%;
      transition: 0.25s all;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:hover {
        background: $sb-green;
      }
    }

    .spot-center {
      display: none;
      cursor: pointer;

      &.visible {
        display: block;
      }

      opacity: 1;
      transition: all 0.25s;

      &.switching {
        opacity: 0 !important;
        transform: scale(0.5) !important;
      }
    }

    .spot-circle-option {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: -15px; /* 2em = 4em/2 */ /* half the width */
    }

    &.hotspot-info {
      .info-close {
        display: none;
        position: absolute;
        top: -18px;
        right: -18px;
        width: 30px;
        height: 30px;
        background-color: #3e3e3e;
        border-radius: 50%;
        padding: 5px;
        border: 1px solid white;
        z-index: 99;
      }

      .info-cloud {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%, -100%) translateZ(0);
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.96);
        width: max-content;
        max-width: 450px;
        min-width: 150px;
        height: max-content;
        box-sizing: border-box;
        clip-path: inset(100% 50% 0px 50% round 30px);
        transition: clip-path 0.4s;
        cursor: default;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #323c47;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 40px 15px;
        gap: 10px;
      }

      .info-link-a {
        text-decoration: underline;
        color: $primary-500;
      }
    }

    &.hotspot-logo {
      margin-left: 0;
      margin-top: 0;

      .spot-center {
        width: 300px;
        height: 300px;
        margin: -150px -150px;
        opacity: 1;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ffffff;
      }

      .logo-container {
        position: relative;
        display: block;
        top: 50%;
        transform: translateY(-50%);

        .logo-image {
          width: 70%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .hotspot-door-ground {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;

    .spot-center {
      position: absolute;
      width: 80px;
      height: 80px;
      margin-left: -40px;
      margin-top: -40px;
      border-radius: 50%;
    }
  }

  .hotspot-door-ring {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient($primary-500, #ffffff77);
    animation: spin 2s infinite linear;
    clip-path: path(
      "M 40 0 A 40 40 0 1 0 40 80 A 40 40 0 1 0 40 0 Z M 40 10 A 30 30 0 1 1 40 70 A 30 30 0 1 1 40 10 Z"
    );
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.viewControlButton {
  position: absolute;
  bottom: 13px;
  left: 50%;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: $light;
  cursor: pointer;

  &.visible {
    display: block;
  }
}

.viewPhotoName {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  height: 50px;
  color: $light;
  width: 50%;
  text-align: center;
  margin: 0 auto;
  padding-top: 5px;

  .photoname-label {
    font-weight: 700;
    font-size: 14px;
    max-width: 250px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .photoname-button {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}

body.view-control-buttons .viewControlButton {
  display: block;
}

/* Hide controls when width is too small */
@media (max-width: 600px) {
  body.view-control-buttons .viewControlButton {
    display: none;
  }

  .photoname-helptext {
    display: none;
  }
}

.viewControlButton .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  color: #ffffff;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
}

.effecticon:hover {
  font-size: 40px;
}

/* Center is at margin-left: -20px */
.viewControlButton-0 {
  margin-left: -195px;
}

.viewControlButton-1 {
  margin-left: -145px;
}

.viewControlButton-2 {
  margin-left: -95px;
}

.viewControlButton-3 {
  margin-left: -45px;
}

.viewControlButton-4 {
  margin-left: 5px;
}

.viewControlButton-5 {
  margin-left: 55px;
}

.viewControlButton-6 {
  margin-left: 105px;
}

.button-back {
  display: none;
  position: absolute;
  bottom: 0;
  height: 58px;
  width: 100%;
  background-color: $background-dark;

  &.visible {
    display: block;
    z-index: 2;
  }

  .photoname-button {
    & svg {
      transition: transform 0.3s ease;
    }
    &.isOpen svg {
      transform: rotate(180deg);
    }
    &.disabled,
    &[disabled] {
      pointer-events: none;
      & svg {
        display: none;
      }
    }
  }

  .viewPhotoRoomList {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    padding: 0.5em;
    background-color: rgba($background-dark, 0.5);
    transform: scaleY(0);
    transition: transform 0.3s ease;
    transform-origin: bottom;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2em;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    z-index: 99;

    &.isOpen {
      transform: scaleY(1);
    }

    .viewPhotoRoomListItem {
      border-radius: 0.5rem;
      border: none;
      position: relative;
      height: 8rem;
      padding: 0;
      cursor: pointer;
      aspect-ratio: 16 / 9;
      flex-shrink: 0; /* Zapobiega kurczeniu się elementów */

      &__label {
        font-size: 0.8rem;
        position: absolute;
        z-index: 10;
        top: 0.5rem;
        left: 0.5rem;
        padding: 0.5em;
        border-radius: 9999px;
        background-color: rgba($background-dark, 1);
        color: #fff;
        text-align: left;
      }

      img {
        border-radius: 0.5rem;
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .viewPhotoContainer {
    .viewPhoto {
      .viewPhotoLoader {
        font-size: 14px;
        color: #e4e4e4;
        position: absolute;
        left: 8px;
        bottom: 65px;
        width: 100%;
        text-align: center;

        .loader {
          display: inline-block;
          vertical-align: middle;
        }

        .label {
          margin-left: 0.25rem;
        }
      }
    }
  }

  .controls {
    @media screen and (max-width: 1040px) {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .chat {
    max-width: 100% !important;
    width: 100%;
  }

  .intro-360 {
    width: 100% !important;
    margin-bottom: -34px;
  }

  .intro-360 {
    .watermark {
      display: none;
    }
  }

  .user-profile {
    width: 66% !important;
  }
}

.spinner {
  &.hidden {
    display: none;
  }

  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  color: $semi-gray;
  z-index: 5;
}

.intro {
  display: none;
  height: 100%;
  z-index: 2;
  position: relative;
  flex: 1;
  color: #ffffff;
  background-size: cover;

  &.visible {
    display: flex;
  }

  .intro-description {
    flex: 1 1 50%;
    background-color: $background-dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;

    @media screen and (max-width: 500px) {
      & *:not(button) {
        display: none;
      }
      & button {
        margin-top: 0;
      }
    }
  }

  &.isMobile {
    .info,
    .intro-logo {
      display: none;
    }
    .start-button {
      margin-top: 0;
      padding: 1em;
      font-size: 1em;
    }

    .intro-title h4 {
      font-size: 1em;
      line-height: 1.5;
      margin-bottom: 0.75em;
    }
  }

  &.isMobile {
    .info,
    .intro-logo {
      display: none;
    }
    .start-button {
      margin-top: 0;
      padding: 1em;
      font-size: 1em;
    }

    .intro-title h4 {
      font-size: 1em;
      line-height: 1.5;
      margin-bottom: 0.75em;
    }
  }

  .intro-logo {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1%;
  }

  .intro-360 {
    width: 36%;
  }

  .intro-360-small {
    width: 36%;
  }

  .intro-desc {
    width: 64%;
    padding: 10px 20px;
  }

  .intro-title h4 {
    font-size: 18px;
    font-size: 56px;
    line-height: 68px;
    letter-spacing: 0.01em;
    display: inline-flex;
    flex-direction: column;
    font-weight: 400;
    margin: 0 0 3rem 0;
  }

  .intro-title--bold {
    font-weight: 700;
    color: #1bad8f;
  }

  .avatars {
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 99999;
  }

  .company-logo {
    min-width: 110px;
    min-height: 110px;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    z-index: 10;

    img {
      width: 100%;
    }
  }

  .owner-avatar {
    justify-content: center;
    height: 115px;
    margin-left: -25px;
    display: none;

    img {
      width: 110px;
      border-radius: 50%;
    }
  }

  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 20px;
    display: none;
  }

  .start-button {
    text-transform: uppercase;
    width: fit-content;
    background: #f7685b;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 21px 48px;
    gap: 14px;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    border: 0;
    transition: background-color 0.3s;
    margin-top: 3rem;

    .fa-circle-play {
      font-size: 16px;
      margin-left: 5px;
    }

    &.active {
      &:hover {
        background-color: #d05145;
        cursor: pointer;
      }
    }
  }

  .info {
    padding: 10px 0px;
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.01em;
  }

  .upper {
    position: absolute;
    top: 0;
    left: 21px;
    right: 21px;
    bottom: 50%;
    margin-bottom: 42px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .center {
    position: absolute;
    top: 50%;
    margin-top: -42px;
    left: 50%;
    margin-left: -42px;
    padding: 10px;

    .play {
      display: none;
      color: $light;
      cursor: pointer;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

      &.visible {
        display: flex;
        margin: 10px;

        &:hover {
          color: $light-pure;
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        }
      }
    }
  }

  .lower {
    position: absolute;
    top: 50%;
    margin-top: 42px;
    left: 21px;
    right: 21px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .description {
      display: flex;
      top: 0;
      position: absolute;
      max-height: 100%;
      overflow: hidden;

      .placeholder {
        display: flex;
        border: none !important;
        width: auto;

        .ql-editor {
          overflow: hidden;
        }
      }
    }
  }

  .elipsis {
    position: absolute;
    bottom: 0;
    display: none;
    font-size: 25px;

    &.visible {
      display: block;
    }
  }
}

@media screen and (max-width: 500px) {
  .intro .start-button {
    margin-top: 0;
    padding: 1em;
    font-size: 1em;
  }

  .intro .intro-title h4 {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 0.75em;
  }
}

@media screen and (max-width: 768px) {
  .intro {
    flex-direction: column;
    gap: 50px;
    background-color: $background-dark;
    justify-content: center;

    .intro-logo {
      order: 1;
      flex: 0;
      justify-content: center;
    }

    .intro-description {
      padding: 0 5%;
      align-items: center;
      order: 2;
      flex: 0;
      background-color: transparent;
    }

    .img360 {
      margin: 0 auto;
    }

    .intro-title {
      text-align: center;
    }

    .intro-title h4 {
      font-size: 32px;
      line-height: 44px;
    }

    .info {
      font-size: 15px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 480px) {
  .poke {
    right: 3px !important;
    bottom: 100px !important;
    width: 295px !important;

    .content {
      font-size: 14px;
    }
  }
}

.message {
  display: none;

  &.visible {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.chat:hover {
  opacity: 1;
}

.chat {
  position: absolute;
  bottom: 8px;
  right: 20px;
  max-height: 42px;
  transition: max-height 0.3s;
  background: transparent;
  color: white;
  padding: 0;
  width: 360px;
  z-index: 99999;
  opacity: 0.8;

  &.hide {
    width: 30px;

    .title {
      display: none;
    }
  }

  &.no-trans {
    transition: none;
  }

  .chat-panel {
    background: $light-pure;
    border-radius: 20px;

    .header {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $primary;
        font-weight: bold;
      }
    }

    .initial-screen {
      padding-right: 5px;
      padding-bottom: 5px;

      .connect-wrapper {
        display: inline-block;
      }
    }

    .name-error,
    .conversation-error {
      color: indianred;
      background-color: lightgray;
      padding: 4px 3px 3px;
      margin-top: 5px;
      font-weight: bold;
    }

    .time {
      font-size: 11px;
      color: $primary;
      margin-right: 5px;
    }

    .conversation {
      overflow-y: auto;
      height: 250px;
      padding: 8px;
      background-color: #f2f3f3;

      .message-container {
        margin: 6px 0;
        color: black;
        display: flex;
        flex-flow: row nowrap;

        .triangle-left,
        .triangle-right {
          visibility: hidden;
        }

        &.in {
          justify-content: space-between;

          .message-wrapper {
            margin-right: 30px;
            margin-left: 5px;
            background-color: rgba(55, 190, 156, 0.43);
            border-radius: 0px 10px 10px 20px !important;
          }
        }

        &.out {
          justify-content: flex-end;

          .message-wrapper {
            margin-right: 5px;
            margin-left: 30px;
            background-color: #c5c5c5;
            border-radius: 10px 0px 20px 10px !important;
          }
        }

        .entry-wrapper {
          width: 85%;
          display: flex;
          flex-flow: row nowrap;

          .triangle-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .triangle-left {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 8.7px 5px 0;
              border-color: transparent $semi-blue transparent transparent;
            }

            .triangle-right {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 0 5px 8.7px;
              border-color: transparent transparent transparent $semi-green;
            }
          }

          .message-wrapper {
            width: 100%;
            overflow: hidden;
            overflow-wrap: break-word;
            border-radius: 4px;

            .msg {
              min-height: 25px;
              padding: 8px 10px;
              font-size: 12px;

              &.error {
                color: red;
              }

              a {
                color: $overlay-dark;
                text-decoration: underline;
              }
            }
          }
        }

        .time-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    .refresh-wrapper {
      display: flex;
      flex-flow: row nowrap;
      padding: 8px 3px 5px 0;
      width: 90%;
      margin: 0 auto;

      .btn-refresh {
        display: block;
        width: 100%;
        background-color: #ffffff;
        border-radius: 4px;
        text-align: center;
        color: #3e3e3e;
        margin: 0;
        border: none;

        .fa {
          margin-right: 10px;
        }
      }

      .btn-refresh:hover {
        color: #37be9c;
      }

      .refresh-text {
        text-transform: uppercase;
        margin-left: 0.5rem;
      }
    }

    .send-wrapper {
      display: flex;
      flex-flow: row nowrap;
      padding: 10px;

      .currentMessage {
        caret-color: #37be9c;
        font-size: 12px;
        background-color: transparent;
        border-style: none;
        font-family: inherit;
        flex: 1 0 auto;
        resize: none;
        overflow-y: hidden;
        padding: 5px 10px;

        &:focus {
          outline: none;
        }
      }

      .send {
        color: $sb-green;
        align-self: center;
        width: 35.55px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .tab-wrapper {
    color: $light;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.in {
      color: black;
      background-color: $semi-dark-blue;
      border-width: 3px;
    }

    .fa-2x {
      font-size: 18px;
    }

    .indicator {
      position: absolute;
      top: 5px;
      right: 0;
      color: #ff8c00;
    }

    .photo-container {
      box-sizing: content-box;
      width: 100%;
      position: relative;
      height: 75px;

      .user-photo-wrapper {
        background: white;
        border: 2px solid;
        position: absolute;
        overflow: hidden;
        border-radius: 50%;
        box-sizing: content-box;
        width: 66px;
        height: 66px;
        transition: bottom 0.2s linear;
        left: -23px;
        top: -23px;

        .user-photo {
          max-width: 100%;
          z-index: 10;
          position: relative;
        }
      }

      .poke {
        background: rgba(255, 255, 255, 1);
        width: 364px;
        position: absolute;
        right: 20px;
        bottom: 57px;
        color: $primary;
        border-radius: 20px;
        box-shadow: 0 0 5px #2d3e50;

        .header {
          background: #1d8e78;
          color: $light;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 10px 10px 0px 0px;

          .btn {
            position: absolute;
            right: -30px;
            top: -30px;
          }

          .title {
            display: none;
          }
        }

        .content {
          display: flex;
          justify-content: center;
          text-align: center;
          padding-top: 5px;
          color: #3e3e3e;
          font-size: 15px;
          padding: 30px 15px;
        }

        .poke-user-photo-wrapper {
          border-radius: 50%;
          box-sizing: content-box;
          width: 75px;
          height: 75px;
          overflow: hidden;
          display: inline-block;
          margin-left: 10px;
          margin-right: -10px;

          .user-photo {
            width: 100%;
            height: auto;
          }
        }

        .subheader {
          position: relative;
          font-size: 1.5rem;
          margin-top: 5px;
          width: 100%;
          margin-bottom: 13px;

          .user-name {
            line-height: 17px !important;
            font-size: 15px;
            font-weight: 400;
            display: inline-block;
            color: white;
          }

          .user-data {
            line-height: 15px;
            font-size: 14px;
            display: block;
          }

          .user-contact {
            color: white;
            margin-bottom: 2px;
            font-weight: 100;
          }

          .user-pos {
            margin-bottom: 8px;
            color: white;
            font-weight: 100;
          }
        }

        .user-photo-pos {
          width: 20%;
          display: inline-block;
          margin-left: 10px;
        }

        .user-profile {
          width: 70%;
          text-align: left;
          display: inline-block;
          margin-left: 16px;
        }

        .actions {
          display: flex;
          justify-content: space-around;
          padding-bottom: 25px;
          padding-top: 10px;
          font-weight: 600;

          .answer {
            margin: 15px 0px;
            cursor: pointer;
          }

          .yes {
            color: #3e3e3e;
            margin: 0 auto;
            font-weight: 200;
            border: 1px solid #3e3e3e;
            padding: 8px 40px;
            border-radius: 18px;
          }

          .yes:hover {
            color: #37be9c;
            border: 1px solid #37be9c;
          }

          .no {
            color: $sb-red;
          }
        }
      }
    }

    .btn-minimize {
      position: absolute;
      right: 10px;
      top: -10px;
    }

    .maximize {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      position: absolute;
    }
  }

  .rtc-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 27%;
    height: 40px;
    position: absolute;
    top: 0px;
    /*.button {
			border-radius: 50%;
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 5px;
			background: white;

			.icon {
				color: black;
			}
		}*/
    video {
      max-width: 40px;
    }

    .indicator-outer {
      position: relative;
      display: flex;
    }

    .indicator-inner {
      background-color: $light-pure;
      border-radius: 50%;
      position: absolute;
      bottom: 20px;
      right: -64px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.green {
        background-color: #8fef8f;
      }

      .connecting {
        color: #1ab294;
        display: flex;
        font-size: 17px;
      }
    }
  }

  &.hide {
    transition: max-height 0.2s ease-out;

    .minimize {
      display: none;
    }

    .photo-container > .user-photo-wrapper {
      /*bottom: 8px;
            transition: bottom 0.2s linear;*/
      display: none;
    }
  }

  &.show {
    transition: height 0.5s ease-in;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 16px #272727;

    .maximize {
      display: none;
    }
  }
}

.hidden {
  display: none !important;
}

.logo {
  position: absolute;
  width: 130px;
  top: 15px;
  left: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 0.96;

  @media screen and (max-width: 768px) {
    width: 17vw;
    border-radius: 10px;
  }
}

.logo img {
  width: 100%;
}

.fullscreen {
  position: absolute;
  left: 5px;
}

.fullscreen-label {
  position: absolute;
  left: 37px;
  white-space: nowrap;
  line-height: 32px;
  font-size: 12px;
}

.fa-2x {
  font-size: 18px;
}

.static-shadow {
  box-shadow: 0 0 10px 0 0 5px #6b6b6b;
}

.btn {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: $light;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.watermark {
  opacity: 0.4;
  position: fixed;
  z-index: 3;
  top: 12px;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);

  &--intro {
    top: auto;
    bottom: 5px;
    left: 10px;
    transform: initial;
  }

  .watermark-gray {
    color: $light-pure;
    letter-spacing: 1px;
    margin-right: 1px;
    font-size: 10px;
  }

  .watermark-green {
    color: $sb-green;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: 600;
  }
}

.btn-chat-new {
  position: absolute;
  text-align: center;
  color: #ffffff;
  right: 0px;
  bottom: -2px;
  font-size: 44px;

  .pulse {
    animation: pulsowanie 1s infinite alternate;
    -webkit-animation: pulsowanie 1s infinite alternate;
  }

  @keyframes pulsowanie {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes pulsowanie {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.btn-link-new {
  display: none;
}

.btn-call-new {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #37be9c;
  z-index: 10;
  top: -8px;
  right: 40px;
}

.btn-hangup-new {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 10;
  top: -8px;
  right: 40px;
  color: #ff2626;
}

.btn-minimize-new {
  width: 50px;
  height: 50px;
  text-align: center;
  color: #525252;
  cursor: pointer;
  position: absolute;
  bottom: -21px;
  right: -15px;
  z-index: 999;
}

.fullscreen-new {
  margin-bottom: 39px;
  margin-right: 5px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.fullscreen-new {
  margin-bottom: -20px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.indicator-new {
  display: none;
}

.img360 {
  height: 120px;
  margin-top: -12px;
  display: block;
  -webkit-filter: drop-shadow(0px 0px 5px #222);
  filter: drop-shadow(0px 0px 5px #222);
  align-self: flex-start;
}

.personname {
  color: black;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 49px;
  top: 14px;
}

.personname #header ~ * {
  font-weight: normal;
}

.PosName {
  font-size: 11px;
  color: #4e4e4e;
  margin-bottom: 3px;
}

.PosHeaderName {
  font-size: 12px;
}

.PosNumer {
  display: inline-block;
  font-size: 11px;
  color: #4e4e4e;
}

.PosContact {
  display: inline-block;
  font-size: 11px;
  color: #4e4e4e;
}

.watermarkpos {
  margin: 0 auto;
  text-align: center;
  text-shadow: 0 0 3px #000000;
}

.viewPhotoShow {
  display: none;
}

@media screen and (max-width: 768px) {
  .chat {
    width: 94%;
  }

  .photo-container {
    margin-right: 8px !important;
  }

  .poke {
    right: 15px !important;
    left: auto !important;
    bottom: 100px !important;
    width: 295px !important;

    .content {
      font-size: 14px;
    }
  }

  .maximize {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
  }

  .intro .title {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 50px 20px 50px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .viewPhotoName {
    left: 10px;
    bottom: 0;

    .photoname-label {
      max-width: 150px;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .viewPhotoShow {
    display: block;
  }

  .minimize {
    left: auto !important;
    right: 131px;
  }
}

.InfoChatMessage {
  position: absolute;
  bottom: 45px;
  right: -150px;
  background: rgba(0, 0, 0, 0.74);
  border: 3px solid white;
  border-radius: 20px 20px 0px 20px;
  display: none;
}

.InfoChatDesc {
  padding: 15px 15px;
  color: white;
  font-size: 14px;
  text-align: center;
}

.InfoChatMessage {
  -webkit-animation: 6s 1 zanikanie;
  animation: 6s 1 zanikanie;
  opacity: 0;
}

@keyframes zanikanie {
  0% {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 50px;
  }

  70% {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 50px;
  }

  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    right: 50px;
  }
}

@-webkit-keyframes zanikanie {
  0% {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 50px;
  }

  70% {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 50px;
  }

  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    right: 50px;
  }
}

@media screen and (max-width: 480px) {
  .personname {
    font-size: 12px;
    font-weight: 600;
  }
}

@media screen and (max-width: 360px) {
  .personname {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #01001fb3;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #01001fb3;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.text-center {
  text-align: center;
}

.button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
  outline: 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  border: 2px solid transparent;

  &:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &.button-light {
    background-color: white;
    color: #3a3a3a;

    &:hover,
    &:focus {
      color: #6d6d6d;
      background-color: #eaeaea;
    }
  }

  &.button-primary {
    background-color: $sb-green;
    color: white;

    &:hover,
    &:focus {
      background-color: lighten($sb-green, 5%);
    }

    &:active {
      background-color: darken($sb-green, 5%);
    }
  }

  &.button-outline-primary {
    background-color: transparent;
    border: 2px solid $sb-green;
    color: $sb-green;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:active {
      color: darken($sb-green, 5%);
      border-color: darken($sb-green, 5%);
    }
  }

  &.button-primary {
    &.disabled,
    &:disabled {
      border-color: transparent;
      background-color: gray;
      pointer-events: none;
    }
  }

  &.button-outline-primary {
    &.disabled,
    &:disabled {
      color: gray;
      border-color: gray;
    }
  }
}

.orline-box {
  display: flex;
  margin: 0.5rem 0;

  .orline {
    background: #bdbdbd;
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .orline-text {
    color: #bdbdbd;
    font-size: 0.9rem;
  }
}

.form-group {
  display: flex;
  flex-direction: row;

  .details {
    height: 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &.error {
    .details {
      color: red;
      margin-left: 6px;
    }

    .form-control {
      border-bottom-color: red;
    }
  }
}

.form-label {
  color: black;
  white-space: nowrap;
  padding: 0.25rem;
  margin: 0.25rem;
  text-align: right;
  width: 30px;
  font-size: 13px;
}

.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid $sb-green;
  outline: 0;
  padding: 0.25rem;
  margin: 0.25rem;
  margin-bottom: 1px;

  &:hover,
  &:focus {
    border-bottom: 2px solid lighten($sb-green, 5%);
    margin-bottom: 0px;
  }
}

.lead-page {
  position: absolute;
  bottom: 20px;
  right: 15px;
  z-index: 1;
  width: 550px;
}

.avatar-info-page {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
}

.form-phone {
  width: 48%;
  float: left;
  margin-right: 20px;
}

.form-email {
  width: 48%;
}
/*.leadPage-select {
	width: 100%;
	border: none;
	border-bottom: 1px solid #37be9c;
}
.leadPage-select:focus {
	outline-offset: 0px;
	outline: -webkit-focus-ring-color auto 0px;
}*/

.full {
  width: 100%;
}

.leadPage-icon {
  position: absolute;
  top: 24px;
  left: 28px;
  color: #37be9c;
  font-size: 46px;
}

@media screen and (max-height: 480px) {
  .backdrop {
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: scroll;

    .lead-page {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0px, 0px);
      width: 100%;
    }
  }

  #lead-page-maximize {
    display: none;
  }

  .photoname-label {
    font-size: 10px !important;
  }

  .chat .tab-wrapper .fa-2x {
    font-size: 14px;
    margin-top: 8px !important;
  }

  .btn-chat-new {
    display: none;
  }

  .InfoChatMessage {
    display: none;
  }

  .intro .avatars {
    display: none;
  }

  .viewport .hotspot .drop-shadow {
    width: 50px;
    height: 50px;
  }

  .chat .tab-wrapper .photo-container .poke {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .lead-page {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .lead-page {
    width: 70%;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
  }

  .form-phone {
    width: 100%;
    float: none;
  }

  .form-email {
    width: 100%;
  }

  .avatar-info-page {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .lead-page {
    width: 80%;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
  }

  .avatar-info-page {
    display: none;
  }
}

.captureInfo {
  position: absolute;
  top: 1rem;
  display: inline-block;
  left: 54%;
  transform: translateX(-50%);
  width: 20%;
  text-align: left;
  color: red;
}

.pulseOnline {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: red;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulseOnline 2s infinite;
  position: absolute;
  left: -34px;
  top: 12px;
}

.pulseOnline:hover {
  animation: none;
}

@-webkit-keyframes pulseOnline {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 19, 19, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(239, 19, 19, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 19, 19, 0);
  }
}

@keyframes pulseOnline {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(239, 19, 19, 0.4);
    box-shadow: 0 0 0 0 rgba(239, 19, 19, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(239, 19, 19, 0);
    box-shadow: 0 0 0 10px rgba(239, 19, 19, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(239, 19, 19, 0);
    box-shadow: 0 0 0 0 rgba(239, 19, 19, 0);
  }
}

.floorplan {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 140px;
  transition: width 250ms;
  font-size: 10px;
  opacity: 0.96;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .floorplan-tabs-list {
    display: none;
  }

  .floorplan-tabs-title {
    display: none;
  }

  .point {
    width: 5px;
    height: 5px;
  }
}

.floorplan-icon {
  display: block;
  color: #ffffff;
  text-align: center;
}

.floorplan:hover {
  z-index: 999;
  font-size: 14px;
  opacity: 1;
  width: 50%;
  right: 10px;
  top: 10px;

  .floorplan-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 99;
    width: 100%;
    background-color: $background-dark;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px 0;
    color: white;
    border-radius: 4px 4px 0 0;
  }

  .floorplan-tabs-list {
    display: block;
    list-style: none;
    padding: 0;
    text-align: center;
    z-index: 99;
  }

  .floorplan-tabs-title {
    display: inline;
  }

  .floorplan-levels-list {
    display: block;
    position: relative;
  }

  .floorplan-icon {
    display: none;
  }

  .floor-title {
    display: none;
  }

  .floorplan-points {
    display: block;
  }

  .point {
    width: 20px;
    height: 20px;
  }
}

.floorplan-tab {
  display: inline-block;
  margin-top: 2px;
  margin-left: 2px;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 6px;
}

.floorplan-tab.active {
  background-color: $background-active;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.floorplan-tab:not(:last-child) {
  margin-right: 0.25rem;
}

.floorplan-tab-link {
  padding: 0.25rem;
}

.floorplan-levels {
  display: flex;
  justify-content: flex-end;
  overflow-y: overlay;
  border-radius: 4px;
}

.floorplan-levels-list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.floorplan-level {
  position: relative;
  display: block;
  padding: 10px;
  background-color: white;
}

.floorplan-level-img {
  width: 100%;
  height: auto;
}

.floorplan:hover .floorplan-level-img {
  width: auto;
  height: auto;
  max-width: 100%;
  opacity: 0.96;
  border-radius: 0 0 4px 4px;
}

.floorplan-points {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  list-style: none;
  padding: 0;
}

.floorplan-point {
  position: relative;
  display: block;
  width: 1px;
}

.floorplan-point .point {
  cursor: pointer;
  position: absolute;
  /*top: 10px;
	left: 10px;*/
  border-radius: 50%;
  background-color: #fd3b3b;
  /*transform: translate(-50%, -50%);*/
  transition: width 250ms, height 250ms;
}

.floorplan-point:hover .point {
  background-color: #1bad8f;
}

.floorplan-point.active .point {
  background-color: #1bad8f;
}

.floorplan:hover .floorplan-point .point {
  width: 20px;
  height: 20px;
}

.floorplan-level-img {
  display: block;
  margin: 0;
}

.floor-title {
  margin-block-start: 0;
  margin-block-end: 4px;
  margin: 0 auto;
  text-align: center;
  margin-top: 6px;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
  font-size: 11px;
}

.fa-map {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

.fa-comments {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

.fa-envelope {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

.fa-expand {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

.fa-phone {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

.fa-phone-slash {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

.fa-minus {
  -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
}

@media screen and (max-width: 1024px) {
  .floorplan-tab {
    display: inline-block;
    margin-top: 2px;
    margin-left: 2px;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 6px;
  }

  .floorplan:hover {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .floorplan {
    width: 100px;
  }

  .floorplan:hover {
    width: 75%;
  }
}

.bigpic-dialog {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 1rem;
  height: 100%;
  width: 100%;

  .bigpic-img {
    display: block;
    max-width: 100%;
    max-height: calc(100% - 26px - 58px);
    z-index: 20;
  }

  .bigpic-info {
    color: $light;
    text-align: center;
    text-shadow: 0 0 2px black, 0 0 2px black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 6px;
  }

  .video-container {
    width: 100%;
    text-align: center;
    max-height: 600px;
    z-index: 20;

    .video {
      width: 100%;
    }
  }
}

.info-photo-thumb,
.info-video-thumb {
  max-width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.info-photo,
.info-video {
  width: 100%;
  position: relative;
}

.info-photo-thumb-desc {
  z-index: 9999;
  font-size: 14px;
  color: #bfbfbf;
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3px 0px;
}

.info-video-play {
  background-color: $primary-500;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 24px 0px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 24px 0px rgba(66, 68, 90, 1);
  cursor: pointer;

  &:hover {
    background-color: $sb-green;
  }

  .info-video-play-icon {
    color: #fff;
  }
}

.info-video {
  cursor: pointer;
}

.info-video-thumb {
  width: 420px;
  height: 236px;
  object-fit: cover;
}

.loading-photo-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 100;
  color: #adadad;
  z-index: 10;
}

.loading-photo-close {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 14px;
  font-weight: 100;
  color: #adadad;
  z-index: 30;
  cursor: pointer;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 0px;
  text-align: center;
}

.tippy-box[data-theme~="green"] {
  background-color: $sb-green;
  color: white;
}

.chat2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 9;
  &__btn-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: .5rem;
    align-items: end;

    @media (max-width: 600px) {
      gap: 0;
    }
  }

  &__actions-container {
    display: flex;
    gap: .5rem;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  &__btn {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2f92ed;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 0px 10px rgba(241, 242, 250, 0.6);
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: #439df2;
    }
  }
  &__chat-container {
    position: absolute;
    bottom: 80px;
    right: 10px;
    background-color: white;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    width: 340px;
    max-width: calc(100vw - 20px);
    height: 570px;
    max-height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    clip-path: inset(0 0 0 0 round 16px);
    transition: clip-path 0.3s;
    z-index: 999;

    &--hidden {
      clip-path: inset(100% 0 0 0 round 16px);
    }
  }
  a {
    color: #1bad8f;

    &:focus {
      outline-color: #34334c;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  .hide {
    display: none;
  }
  &__new-message-list {
    position: absolute;
    bottom: 70px;
    right: 55px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    color: white;

    @media screen and (max-width: 480px) {
      bottom: 90px;
      right: 10px;
    }
  }
  &__new-message-container {
    position: relative;
    background-color: rgba(1, 0, 31, .701961);
    border-radius: 16px;
    min-width: 64px;
    max-width: 340px;
    width: max-content;
    font-size: 14px;
    overflow: hidden;
    animation: show-new-message 0.5s linear;
    box-shadow: 4px 3px 12px #646464;

    &:last-child {
      border-radius: 16px 16px 0 16px;
    }

    @keyframes show-new-message {
      from {
        max-height: 0;
      }
      to {
        max-height: 150px;
      }
    }

    &--hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.3s, opacity 0.3s linear;
    }

    &:last-child::after {
      content: "";
      position: absolute;
      top: 100%;
      right: 23px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
    }
  }
  &__new-message-content {
    padding: 15px;

    &--clickable {
      cursor: pointer;
      user-select: none;
    }
  }
}
.chat-btn {
  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  &__notifications {
    background-color: #f7685b;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 12px;
    text-align: center;
  }
  &__mute {
    background-color: hsl(5, 0%, 66%);
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: hsl(5, 0%, 9%);
    font-size: 15px;
    text-align: center;
    cursor: pointer;

    @media (max-width: 600px) {
      width: 25px;
      height: 25px;
    }
  }
  &__hide-chat-notifications {
    background-color: hsl(5, 0%, 66%);
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: hsl(5, 0%, 9%);
    font-size: 15px;
    text-align: center;
    cursor: pointer;

    @media (max-width: 600px) {
      width: 25px;
      height: 25px;
    }
  }
}
.chat-container {
  &__header {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebedfa;
  }
  &__heading {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding: 0 5px;
  }
  &__minimize {
    cursor: pointer;
    border: none;
    background-color: transparent;
    transition: background-color 0.2s;
    border-radius: 3px;
    &:hover {
      background-color: #ebedfa;
    }
  }
  &__conversation {
    padding: 12px 15px;
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    scroll-behavior: smooth;
  }

  &__message-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__message {
    padding: 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    &--right {
      @extend .chat-container__message;
      background-color: #34334c;
      color: white;
      align-self: flex-end;
      margin-left: 15%;
    }
    &--left {
      @extend .chat-container__message;
      background-color: #f3f3f3;
      color: #323c47;
      align-self: flex-start;
      margin-right: 15%;
    }
    &--tip {
      @extend .chat-container__message;
      background-color: #676679;
      // background-color: #34334c;
      color: white;
    }
    &--title {
      @extend .chat-container__message;
      padding: 5px;
      text-align: center;
    }
    &--clickable {
      cursor: pointer;
      user-select: none;
    }
    &--separator {
      border-bottom: 1px solid #ccc;
      margin: 10px 0;
    }
  }
  &__contact-form {
    background-color: #f8f9ff;
    color: #323c47;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 12px;
    border-top: 1px solid #ebedfa;
    border-radius: 16px;
  }
  &__chat-form {
    background-color: #f8f9ff;
    color: #323c47;
    border-top: 1px solid #ebedfa;
    padding: 12px;
    display: flex;
    gap: 5px;
    position: relative;

    em-emoji-picker {
      position: absolute;
      bottom: 100%;
    }

    .icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background-color: #ebedfa;
      }
      align-self: center;

      &--input {
        @extend .icon-button;
        position: absolute;
        top: 1px;
        right: 1px;
        height: calc(100% - 2px);
        border-radius: 0 4px 4px 0;
        padding: 0 5px;
      }
    }
  }
  &__message-container {
    position: relative;
    flex-grow: 1;
  }
  &__message-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
  }
  &__message-button {
    flex: 1 1;
    border-radius: 20px;
    padding: 5px 8px;
    border: 0;

    &:not(.chat-container__message-button--selected),
    &:not(.chat-container__message-button--selected)
      ~ button:not(.chat-container__message-button--selected) {
      cursor: pointer;
    }

    &--positive {
      @extend .chat-container__message-button;
      background-color: #ffffff;
      color: #000000;
      transition: color 0.5s background-color 0.5s;

      &:hover {
        background-color: darken(#ffffff, 10%);
      }

      &.chat-container__message-button--selected {
        background-color: darken(#ffffff, 20%);
      }
    }

    &--negative {
      @extend .chat-container__message-button;
      background-color: #ce554a;
      color: white;

      &:hover {
        background-color: darken(#ce554a, 10%);
      }

      &.chat-container__message-button--selected {
        color: silver;
        background-color: darken(#ce554a, 15%);
      }
    }
  }

  &__message-input-wrapper {
    position: relative;
    width: 100%;
    margin: 10px 0 10px 0;
  }

  &__message-input {
    border: 1px solid #d3d8f3;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 8px 44px 8px 8px;
    box-sizing: border-box;
  }

  &__message-form-submit {
    position: absolute;
    top: 2px;
    right: 2px;
    height: calc(100% - 4px);
    padding: 8px 16px;
    background-color: #1bad8f;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #49bda5;
    }
  }
  &__checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.contact-form {
  &__title {
    font-size: 11px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
  }
  &__rules {
    font-size: 9px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #000000;
  }
  &__rules-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__submit {
    background: #1bad8f;
    border-radius: 4px;
    padding: 10px 16px;
    text-align: center;
    border: 0;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #49bda5;
    }
    &:focus {
      outline-color: #34334c;
    }
  }
  &__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.textinput {
  background-color: transparent;
  border: 1px solid #d3d8f3;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.01em;
  &:focus {
    outline-color: #34334c;
    & ~ .icon-button {
      top: 2px;
      right: 2px;
      height: calc(100% - 4px);
      padding-right: 4px;
    }
  }
  &--message {
    @extend .textinput;
    width: 100%;
    padding-right: 35px;
  }
}

.btn-primary {
  background: #1bad8f;
  border-radius: 4px;
  padding: 0.5em 1em;
  text-align: center;
  border: 0;
  font-size: 1rem;
  text-align: center;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #49bda5;
  }
  &:focus {
    outline-color: #34334c;
  }
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

@import "select";
@import "presentations";
@import "loader";
